.optionContainer {
  .MuiFormControl-marginNormal {
    margin: 0;
  }
  .MuiInput-input {
    padding: 18px 0 7px 3px !important;
  }
  .MuiInputBase-multiline {
    padding: 0;
  }
  .Auto-MuiInputBase-multiline {
    padding: 0;
  }
}